import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import * as events from '../lib/events';

export default (el, props) => {
    const BP_IS_ACTIVATED = 1200;
    
    const $el = $(el);
    const { isFirst } = props;
    
    const $video = $el.find('video');
    const $slideWidgets = $el.find('[data-slide-widget]');
    
    const $phone = $el.find('[data-phone-case]');
    const $frontpageHero = $el.find('[data-frontpage-hero]');

    let panelObserver = null;
    let isLoaded = false;

    const init = () => {
        if (Viewport.breakpoint.size < BP_IS_ACTIVATED) {
             initSlide();
             
             if (isFirst) {
                 initStickyPhone();
                 activate();
             }
        }

        Viewport.on('resize', onResize);
    };

    const destroy = () => {

    };
    
    const onResize = e => {
        
    };
    
    const initStickyPhone = () => {
        const $frontpageHero = $('body').find('[data-frontpage-hero]');
        const $frontpageHeroInner = $('body').find('[data-frontpage-hero-inner]');
        console.log($frontpageHeroInner);
        let startY = -$el.offset().top + $frontpageHeroInner.offset().top + $frontpageHeroInner.height() + 30;
        
        gsap.set($phone.get(0), {
            y: startY
        });
        
        gsap.to($phone.get(0), {
            scrollTrigger: {
                trigger: $el.get(0),
                scrub: true,
                start: "top bottom",
                end: "top top",
            },
            y: 0,
            ease: 'sine.inOut'
        })
    };

    const initSlide = () => {
        initVideo();
        
        if ($slideWidgets.length > 0) {
            gsap.set($slideWidgets.nodes, { opacity: 0, y: 80 });
        }
        
        panelObserver = new IntersectionObserver(onPanelObserve, {
            threshold: [0, 0.25, 0.5, 0.75, 1]
        });

        panelObserver.observe($el.get(0));
    };
    
    const initVideo = () => {
        $video.attr('src', $video.data('video'));
        $video.get(0).pause();
    };
    
    const onPanelObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0.5)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }
        intersecting = intersecting.sort((a, b) => parseFloat(b.intersectionRatio) - parseFloat(a.intersectionRatio)).map(({ target }) => target);
        activate();
    };
    
    const activate = () => {
        $video.get(0).play();
        
        if ($slideWidgets.length > 0) {
            gsap.to($slideWidgets.nodes, { duration: 1, delay: 0.5, stagger: 0.2, ease: 'quart.out', opacity: 1, y: 0 });
        }
    };

    return {
        init,
        destroy
    };

};
