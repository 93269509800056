import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

import * as events from '../lib/events';

export default el => {
    const BP_IS_ACTIVATED = 1200;

    const $el = $(el);
    const $slides = $el.find('[data-slide-panel]');
    const $nav = $el.find('[data-slides-nav]');
    const $scrollPanels = $el.find('[data-scroll-panel]');
    const $slidesBackground = $el.find('[data-slides-background]');

    const $fixedPhone = $el.find('[data-fixed-phone]');

    let tl = null;
    let panelObserver = null;
    let moduleObserver;
    let activePanel;
    let hasPreloaded = false;
    let isInited = false;
    let lastTime = 1;
    let resizeTimeout = null;

    const init = () => {
        $nav.on('click', 'button', e => {
            onNavButtonClick($(e.triggerTarget));
        });

        panelObserver = new IntersectionObserver(onPanelObserve, {
            threshold: [0, 0.25, 0.5, 0.75, 1]
        });

        $el.find('[data-scroll-panel]').each(panel => {
            panelObserver.observe(panel);
        });

        moduleObserver = new IntersectionObserver(onModuleObserve, {});
        moduleObserver.observe(el);

        Viewport.on('resize', onResize);

        if (Viewport.breakpoint.size >= BP_IS_ACTIVATED) {
            initSlides();
            setTimeout(() => {
                Dispatch.emit(events.CHANGE_VIDEO, { index: 0 });
            }, 100);
        }

        $fixedPhone.css({ opacity: 1 });
        $slides.css({ opacity: 1 });
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        $nav.off('click');
    };

    const onResize = () => {
        clearTimeout(resizeTimeout);

        resizeTimeout = setTimeout(() => {
            reset();

            if (Viewport.breakpoint.size >= BP_IS_ACTIVATED) {
                initSlides();
            }
        }, 200);
    };

    const reset = () => {
        if (tl) {
            lastTime = tl.time();
            tl.kill();
        }

        $el.find('[data-slide-content-element]').attr('style', null);

        isInited = false;
    };

    const initSlides = () => {
        console.log('initSlides');
        reset();

        gsap.set($el.find('[data-slide-content-element]').nodes, { opacity: 0, y: 100 });
        gsap.set($slidesBackground.get(0), { scaleY: 0 });

        $scrollPanels.each((scrollPanel, index) => {
            const bullet = $nav.find('[data-progress-bullet="' + index + '"]').get(0);
            const line = $nav.find('[data-progress-line="' + index + '"]').get(0);
            const slidePanel = $slides.get(parseInt(scrollPanel.dataset.scrollPanel));

            gsap.to(bullet, {
                scrollTrigger: {
                    trigger: scrollPanel,
                    toggleActions: "play none none reverse",
                    start: 'top 30%'
                },
                duration: 0.1,
                opacity: 1,
                onComplete: () => {
                    $(bullet).addClass('progress-bullet-animation');
                },
                onReverseComplete: () => {
                    $(bullet).removeClass('progress-bullet-animation');
                }
            });

            if (line) {
                gsap.to(line, {
                    scrollTrigger: {
                        trigger: scrollPanel,
                        scrub: 0.3,
                        start: "top top-=10%",
                        end: "top top-=60%"

                    },
                    scaleY: 1,
                    transformOrigin: "left top",
                    ease: "none"
                });
            }

            if (slidePanel) {
                ScrollTrigger.create({
                    trigger: scrollPanel,
                    start: "top 30%",
                    end: "+=100%",
                    //markers: true,
                    onEnter: function() {
                        animateIn(slidePanel, 'down', index >= $scrollPanels.length - 1)
                    },
                    onEnterBack: function() {
                        animateIn(slidePanel, 'up', index >= $scrollPanels.length - 1)
                    },
                    onLeave: function() {
                        animateOut(slidePanel, 'down', index >= $scrollPanels.length - 1)
                    },
                    onLeaveBack: function() {
                        animateOut(slidePanel, 'up', index >= $scrollPanels.length - 1)
                    }
                });
            }
        });

        gsap.to($fixedPhone.get(0), {
            scrollTrigger: {
                trigger: $scrollPanels.get(0),
                scrub: 1,
                start: "top bottom",
                end: "top top",
                //markers: true
            },
            y: -Viewport.height / 2,
            ease: "none"
        });

        gsap.to($fixedPhone.find('[data-fixed-phone-inner]').get(0), {
            scrollTrigger: {
                trigger: $scrollPanels.get($scrollPanels.length - 1),
                scrub: true,
                start: "top top-=100%",
                end: "top top-=180%",
                //markers: true
            },
            y: -Viewport.height,
            ease: "none"
        });

        gsap.to($slidesBackground.get(0), {
            scrollTrigger: {
                trigger: $scrollPanels.get($scrollPanels.length - 1),
                scrub: 2.5,
                start: "top 30%",
                end: "top 30%",
                //markers: true
            },
            scaleY: 1,
            ease: "none"
        });


        isInited = true;
    };

    const animateIn = (slidePanel, direction) => {
        direction = direction || 'down';

        let elements = $(slidePanel).find('[data-slide-content-element]').nodes

        if (direction === 'up') {
            elements = elements.reverse();
        }

        gsap.to(elements, {
            duration: 1,
            delay: 0.3,
            opacity: 1,
            y: 0,
            ease: 'quart.out',
            stagger: 0.2
        });
    };

    const animateOut = (slidePanel, direction, isLast) => {
        direction = direction || 1;

        let elements = $(slidePanel).find('[data-slide-content-element]').nodes

        if (direction === 'up') {
            elements = elements.reverse();
        }

        if (!(isLast && direction === 'down')) {
            gsap.to(elements, {
                duration: 0.3,
                opacity: 0,
                y: direction === 'down' ? -100 : 100,
                ease: 'sine.in',
                stagger: 0.1,
                overwrite: true
            });
        }
    };

    const onNavButtonClick = $button => {
        const index = $button.find('[data-progress-bullet]').data('progress-bullet');
        const $panel = $scrollPanels.eq(index);
        gsap.to(window, { duration: 0.3, ease: 'sine.out', scrollTo: { y: $panel.offset().top + 10 } });
    };

    const onPanelObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0.5)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }
        intersecting = intersecting.sort((a, b) => parseFloat(b.intersectionRatio) - parseFloat(a.intersectionRatio)).map(({ target }) => target);
        setActivePanel(intersecting.shift());
    };

    const setActivePanel = panel => {
        if (panel === activePanel) {
            return;
        }

        if (activePanel) {
            //hidePanel(parseInt(activePanel.dataset.scrollPanel));
        }

        activePanel = panel;
        Dispatch.emit(events.CHANGE_VIDEO, { index: parseInt(panel.dataset.scrollPanel) });
    };

    const onModuleObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }

        if (!hasPreloaded) {
            //$el.find('img.lazyload').addClass('lazypreload');
            hasPreloaded = true;
            moduleObserver.disconnect();
        }
    };

    return {
        init,
        destroy
    };

};
