import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

import * as events from '../lib/events';

export default el => {
    const $el = $(el);

    let breakpoint = Viewport.breakpoint.size;

    let isHidden = false;
    let isLocked = false;
    let hiddenThreshold = 80;
    let currentScrollTop = Viewport.scrollTop;

    let wasTransparent = false;

    const init = () => {
        $el.removeClass('js:opacity-0');
        
        if ($el.hasClass('is-transparent')) {
            wasTransparent = true;
        }
        
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $el.on('focusin', 'a,button', onInnerFocus);

        onResize();
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    /**
     * Scroll behaviour
     */
    const hide = (force) => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $el.addClass('is-forced');
            
            setTimeout(() => {
                $el.removeClass('is-forced');
            }, 600)
        }
        
        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $el.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $el.removeClass('is-locked');
    };

    const onBreakpoint = () => {
        breakpoint = Viewport.breakpoint.size;
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }
        
        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }

        if (wasTransparent) {
            if (scrollTop < hiddenThreshold && !$el.hasClass('is-transparent')) {
                $el.addClass('is-transparent');
            } else if (scrollTop >= hiddenThreshold && $el.hasClass('is-transparent')) {
                $el.removeClass('is-transparent');
            }
        }

        currentScrollTop = scrollTop;
    };

    const onInnerFocus = e => {
        show();
    };

    const getThreshold = () => {
        if (breakpoint < 1200) {
            return 88;
        }

        return 116;
    };

    const onResize = () => {
        hiddenThreshold = getThreshold();

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };


    return {
        init,
        destroy
    };

};
