import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import * as events from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $bg = $el.find('#eandf-background');
    const $clock = $el.find('#eandf-clock');

    let isLoaded = false;

    const init = () => {
        gsap.set($bg.nodes, { y: 100, opacity: 1 });
        gsap.set($clock.nodes, { opacity: 0 });
        
        gsap.to($bg.nodes, {
            scrollTrigger: {
                trigger: el,
                scrub: 0.3,
                start: "top bottom",
                end: "top top+=10%"

            },
            y: 0,
            transformOrigin: "left top",
            ease: "none"
        });
        
        gsap.to($clock.nodes, {
            scrollTrigger: {
                trigger: el,
                scrub: 1,
                start: "top center",
                end: "top center"

            },
            opacity: 1,
            ease: "none"
        });
    };

    const destroy = () => {

    };
    

    return {
        init,
        destroy
    };

};
