import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $elements = $el.find('[data-animation-element]');

    const init = () => {
        gsap.set($elements.nodes, { y: Viewport.breakpoint.size > 750 ? 50 : 10, opacity: 0 });
        
        gsap.to($elements.nodes, { 
            scrollTrigger: {
                trigger: el,
                start: "top top+=60%",
                toggleActions: "play none none reverse",
            },
            duration: 1.2, 
            stagger: 0.1, 
            ease: 'quart.out', 
            y: 0, 
            opacity: 1 
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
