import $ from './core/Dom';
import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';

gsap.defaults({ overwrite: 'auto' });

let qrModalOpen = false;
const $body = $('body');

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    // QR modal
    $body.on('click', '[data-open-qr-button]', e => {
        e.preventDefault();
        showQrModal();
    });

    $body.on('click', '[data-close-qr-button]', e => {
        e.preventDefault();
        closeQrModal();
    });

    document.addEventListener('keyup', onKeyUp);
};

const showQrModal = () => {
    const $target = $body.find('#qr-modal');

    const $inner = $target.find('[data-inner-box]');

    gsap.set($target.get(0), { display: 'flex', opacity: 0 });
    gsap.set($inner.get(0), { opacity: 0, y: -200 });

    gsap.to($target.get(0), { duration: 0.2, ease: 'sine.out', opacity: 1 });
    gsap.to($inner.get(0), { duration: 0.2, delay: 0.2, ease: 'sine.out', opacity: 1 });
    gsap.to($inner.get(0), { duration: 0.6, delay: 0.2, ease: 'back.out', y: 0 });

    qrModalOpen = true;
};

const closeQrModal = () => {
    const $target = $body.find('#qr-modal');
    gsap.to($target.get(0), {
        duration: 0.1, ease: 'sine.out', opacity: 0, onComplete: () => {
            gsap.set($target.get(0), { display: '' });
        }
    });
    qrModalOpen = false;
};

const onKeyUp = e => {
    const key = e.keyCode || e.which;

    if (qrModalOpen && key === 27) {
        closeQrModal();
    }
};

require('doc-ready')(init);
