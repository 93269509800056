import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    let $current = null;

    const init = () => {
        $el.on('click', 'button', e => {
            const $button = $(e.triggerTarget);
            toggle($button);
        });
    };

    const destroy = () => {
        $el.off('click');
    };

    const toggle = $button => {
        const $content = $button.parent().next();
        const expanded = $button.attr('aria-expanded') === 'true' || false

        $button.attr('aria-expanded', !expanded);

        if (expanded) {
            collapse($content);
            $current = null;
        } else {
            expand($content);
            $current = $button;
        }
    };

    const collapse = $content => {
        gsap.to($content.get(0), {
            duration: 0.5, height: 0, ease: 'quint.inOut', onComplete: () => {
                $content.attr('hidden', '');
            }
        });

        $content.parent().removeClass('selected');
    };

    const expand = $content => {
        if ($current !== null) {
            //toggle($current)
        }

        $content.attr('hidden', null);
        gsap.set($content.get(0), { height: 0, overflow: 'hidden' });
        gsap.to($content.get(0), { duration: 0.7, height: 'auto', ease: 'quint.inOut' });
        $content.parent().addClass('selected');
    };

    return {
        init,
        destroy
    };
};
