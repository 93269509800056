import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { loadFlickity } from "../lib/async-bundles";

export default (el, props) => {
    const SLIDE_SELECTOR = '[data-slide-item]';

    const $el = $(el);
    
    const $slider = $el;
    const $slides = $el.find(SLIDE_SELECTOR);

    let Flickity;
    let slideshow;
    let lastW = 0;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };
    
    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            height: `${$slider.height()}px`
        });
        
        $slides.each(item => {
            const $item = $(item);
            $item.css({ width: $item.width(), display: 'block' });
        });
        
        $slider.css({ display: 'block' });
        
        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: false,
            freeScroll: true,
            contain: true,
            accessibility: false,
            initialIndex: Math.floor($slides.length/2)
        });
    };
    
    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        
        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');
        
        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        if (lastW !== Viewport.width) {
            initFlickity();
            lastW = Viewport.width;
        }
    };
    
    return {
        init,
        destroy
    };

};
