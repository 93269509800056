import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import * as events from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $videos = $el.find('video');

    let currentIndex = -1;

    const init = () => {
        $videos.each(video => {
            const $video = $(video);
            $video.attr('src', $video.data('video'))
        });

        $videos.eq(0).css({ opacity: 1 });

        Dispatch.on(events.CHANGE_VIDEO, onChangeVideo);
    };

    const destroy = () => {
        Dispatch.off(events.CHANGE_VIDEO, onChangeVideo);
    };

    const onChangeVideo = (key, data) => {
        const { index } = data;

        if (currentIndex !== index) {
            playVideo(index);
        }
    };

    const playVideo = index => {
        if (currentIndex !== -1) {
            $videos.eq(currentIndex).css({ opacity: 0 });
            $videos.get(currentIndex).pause();
        }
        
        $videos.eq(index).css({ opacity: 1 });
        $videos.get(index).currentTime = 0;
        $videos.get(index).play();
        
        
        currentIndex = index;
    };

    return {
        init,
        destroy
    };

};
